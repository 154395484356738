import { handleErrorWithSentry, init, Replay } from "@sentry/sveltekit";
import { PUBLIC_ENVIRONMENT as environment } from '$env/static/public';

init({
  debug: false,
  dsn: 'https://97d3e1bbc9b199da6547047a10ebad49@o4505464480333824.ingest.sentry.io/4506426732314624',
  enabled: environment !== 'local',
  environment: environment,
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [new Replay()]
});

export const handleError = handleErrorWithSentry();
